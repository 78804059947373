<template>
  <div class="wh-space-bottom">
    <b-card>
      <edit-field-header
        :title="title"
        @routerBack="$router.back()"
      />

      <edit-field
        :is-edit="false"
        :fields="fields"
        :row-data="row_data"
        post-api="/kioskmanage/AddDeviceModel"
        finish-router="kioskPeripheralModel"
        permission=""
        @beforeSubmit="beforeSubmit"
      >
      </edit-field>
    </b-card>
  </div>
</template>

<script>
import fields, { reloadFieldData } from './fields'
import EditField from '@/components/EditField2'
import EditFieldHeader from '@/components/EditFieldHeader'
import common from '@/common'
import { flattenObject, expandObject } from '@/libs/ez-utils'

export default {
  name: 'Add',
  components: {
    EditFieldHeader,
    EditField,
    fields,
  },

  data() {
    return {
      title: common.getMenuName('kioskPeripheralModel') + ' - ' + common.getI18n('common.add'),
      fields,
      row_data: {
        purposes: [],
      }
    }
  },

  created() {
    reloadFieldData()
  },

  mounted() {
  },

  methods: {
    beforeSubmit(callback) {
      const row_data = this.row_data
      callback(true, {
        // "ModelID": row_data.deviceModelID,
        "Name": row_data.name,
        "Brand": row_data.brand,
        "Model": row_data.model,
        "BleName": row_data.bleName,
        "ModelRemark": row_data.modelRemark,
        "Purposes": row_data.purposes,
      })
    },
  },
}
</script>

<style scoped>

</style>
